import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faUser,
  faPhone,
  faPaperPlane,
  faNoteSticky,
  faComments,
  faSortUp,
  faFileLines,
  faClock,
  faTicket,
  faPhoneVolume,
  faBorderNone,
  faPlus,
  faPenToSquare,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUser,
  faPhone,
  faPaperPlane,
  faNoteSticky,
  faComments,
  faSortUp,
  faFileLines,
  faClock,
  faTicket,
  faPhoneVolume,
  faBorderNone,
  faPlus,
  faPenToSquare,
  faAngleLeft,
  faAngleRight
);

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store).use(router).mount("#app");
app.config.globalProperties.apiHost = `${process.env.VUE_APP_API}`;
