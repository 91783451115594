import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import UserManagement from "@/views/UserManagement.vue";
const routes = [
  {
    path: "/",
    name: "home",
    meta: { requiresAuth: true },

    component: () =>
      import(/* webpackChunkName: "lead" */ "../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/LoginView.vue"),
  },
  {
    path: "/lead_details/:lead_id",
    name: "LeadDetails",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/LeadDetails.vue"),
  },
  {
    path: "/upload",
    name: "Upload",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/UploadView.vue"),
  },
  {
    path: "/lead",
    name: "Lead",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/LeadManagement.vue"),
  },
  {
    path: "/leads",
    name: "Leads",
    props: true,
    component: () => import("../views/LeadsPage.vue"),
  },
  {
    path: "/deals",
    name: "Deals",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/DealsView.vue"),
  },
  {
    path: "/management",
    name: "User",
    meta: { requiresAuth: true },
    component: UserManagement,

    // component: () => import("../views/UserManagement.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/PaymentsView.vue"),
  },
  {
    path: "/communications",
    name: "Communications",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/CommunicationsView.vue"),
  },
  {
    path: "/reports",
    name: "Report",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/ReportView.vue"),
  },
  {
    path: "/workspace",
    name: "Help",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/HelpView.vue"),
  },
  {
    path: "/api-documentation",
    name: "API",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/ApiDocs.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/:user/board/",
    name: "TaskHome",
    props: true,
    meta: { requiresAuth: true },

    component: () => import("../views/Task/HomeView.vue"),
  },
  // {
  //   path: "/:user/board/:board_name",
  //   name: "TaskBoard",
  //   props: true,
  //   meta: { requiresAuth: true },
  //   component: () => import("../views/Task/TaskBoardView.vue"),
  // },
  {
    path: "/:user/board/task-list/:project_id",
    name: "TaskBoard",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/Task/TaskBoardViewsample2.vue"),
  },
  {
    path: "/:lead_id/workspace/:lead_mail",
    name: "WorkspaceCreate",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/WorkspaceCreateView.vue"),
  },
  {
    path: "/chart-testing/:project_id",
    name: "ChartTesting",
    props: true,

    component: () => import("../views/ChartTestingView.vue"),
  },
  {
    path: "/sales-team/",
    name: "SalesTeam",
    props: true,
    meta: { requiresAuth: true },
    component: () => import("../views/SalesTeamMembers.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
